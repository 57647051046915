import styled from '@emotion/styled'

import React from 'react'

const StyledFooter = styled.footer`
  margin-top: 2rem;
  padding: 1rem;
`
const Copyright = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.color.muted};
  text-align: right;
`

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <StyledFooter>
      <Copyright>© ARCHILTON LTD {year}</Copyright>
    </StyledFooter>
  )
}

export default Footer
