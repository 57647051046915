import React, { FC } from 'react'
import '@fontsource/quicksand/variable.css'
import { ThemeProvider } from 'emotion-theming'
import styled from '@emotion/styled'
import useSiteMetadata from '../components/SiteMetadata'
import CSSProvider from '../css/CSSProvider/CSSProvider'
import { theme } from '../css/theme'
import Header from '../modules/Header/Header'
import SEO from '../components/SEO/SEO'
import Footer from '../components/Footer/Footer'

const CentreHeader = styled(Header)`
  justify-content: center;
`

const Main = styled.main`
  flex-grow: 1;
`

export type LayoutProps = {
  noHeader?: boolean
}

const Layout: FC<LayoutProps> = ({ children, noHeader }) => {
  const { title, description, links } = useSiteMetadata()

  return (
    <>
      <SEO title={title} description={description} />
      <ThemeProvider theme={theme || {}}>
        <CSSProvider />
        {!noHeader && <CentreHeader links={links} />}
        <Main>{children}</Main>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default Layout
