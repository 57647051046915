export enum Alignment {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center',
}

export enum LinkType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}
