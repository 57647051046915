import React from 'react'
import { Global, css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

// This component never has children
const CSSProvider = () => {
  const theme = useTheme()
  return (
    <Global
      styles={css`
        body {
          font-family: ${theme.bodyFont};
          height: 100%;
          background-color: ${theme.color.background};
          margin: 0;
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;
          min-height: 100vh;
        }
        #___gatsby,
        #gatsby-focus-wrapper {
          flex-grow: 1;
          min-height: 100vh;
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;
        }
        ::-webkit-scrollbar {
          width: 6px !important;
          height: 6px !important;
        }
        ::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
        }
        ::-webkit-scrollbar-track {
          background: hsla(0, 0%, 100%, 0.1);
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${theme.titleFont};
          line-height: 0.9em;
          margin-block-start: 1.1em;
          margin-block-end: 1.1em;
        }

        h1 {
          font-size: clamp(2.5rem, 4vw, 6rem);
        }

        h2 {
          font-size: clamp(1.95rem, 2.3vw, 3.75rem);
        }

        h3 {
          font-size: clamp(1.5rem, 1.5vw, 1.85rem);
        }

        p {
          line-height: 1.65em;
          margin-block-start: 1.2em;
          margin-block-end: 1.2em;
          font-size: clamp(1rem, 1.2vw, 1.2rem);
          text-align: justify;
        }

        ol,
        ul {
          padding-inline-start: 2.2em;
          li {
            padding-left: 1.2em;
            margin-block-start: 1.2em;
            margin-block-end: 1.2em;
          }
        }
        input {
          font-family: ${theme.bodyFont};
          padding: 10px 0;
          font-size: 1em;
          border: none;
          box-sizing: border-box;
          margin: auto;
          width: 100%;
          text-indent: ${theme.inputTextIndent};

          :focus {
            border: none;
          }

          ::placeholder {
            font-family: inherit;
            color: ${theme.color.muted};
          }
        }
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        img {
          max-width: 100%;
        }

        @supports not (font-size: clamp(2.5em, 4vw, 5em)) {
          h1 {
            @media (min-width: 350px) {
              font-size: 2.5em;
            }
            @media (min-width: 992px) {
              font-size: 3em;
            }
            @media (min-width: 1200px) {
              font-size: 5em;
            }
          }
          h2 {
            @media (min-width: 350px) {
              font-size: 1.75em;
            }
            @media (min-width: 992px) {
              font-size: 2em;
            }
            @media (min-width: 1200px) {
              font-size: 3.5em;
            }
          }
          h3 {
            @media (min-width: 350px) {
              font-size: 1.5em;
            }
            @media (min-width: 992px) {
              font-size: 1.8em;
            }
            @media (min-width: 1200px) {
              font-size: 2.25em;
            }
          }
        }
        /* Adjust the position of the line numbers */
        .gatsby-highlight {
          background-color: #2d2d2d;
          border-radius: 0.3em;
          margin: 0.5em 0;
          padding: 1em;
          overflow: auto;

          pre[class*='language-'].line-numbers {
            padding: 0;
            padding-left: 2.8em;
            overflow: initial;
          }
        }
      `}
    />
  )
}

export default CSSProvider
