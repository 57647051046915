import React, { FC } from 'react'

import Link from '../../components/Link/Link'
import { LinkType } from '../../types/Enums'
import type { Theme } from '../../types'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'

const NavContainer = styled.nav`
  display: flex;
`

const NavLink = styled(Link)<{ theme: Theme }>`
  padding: 0.5em;
  font-weight: bold;
  color: ${({ theme }) => theme.color.primary};
  transition: color 0.2s linear;
  font-size: clamp(1.25em, 1.55vw, 2em);
  text-decoration: underline;
  :hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
  @supports not (font-size: clamp(1.25em, 1.5vw, 2em)) {
    a {
      @media (min-width: 768px) {
        font-size: 1.8em;
      }
    }
  }
`

export type HeaderProps = {
  links: GatsbyTypes.MarkdownRemarkFrontmatterNavItems[]
  className?: string
}

const Header: FC<HeaderProps> = ({ links = [], className }) => {
  const { pathname } = useLocation()
  return (
    <NavContainer className={className}>
      {/* <NavLink to="mailto:adam@archilton.co.uk">adam@archilton.co.uk</NavLink> */}
      {links &&
        links.map(({ url, label, linkType }) => {
          const to = `${linkType === LinkType.INTERNAL && '/'}${url}`
          return pathname !== to ? (
            <NavLink key={url} to={to}>
              {label}
            </NavLink>
          ) : null
        })}
    </NavContainer>
  )
}

export default Header
