import { Theme } from '../types'

const defaults = {
  primary: '#2b78e4',
  secondary: '#87CEFA',
  tertiary: '#D45500', // '#D45500',
  background: 'rgb(250,250,250)',
  danger: '#7B0D1E',
}

export const theme: Theme = {
  color: {
    primary: defaults.primary,
    secondary: defaults.secondary,
    tertiary: defaults.tertiary,
    primaryHover: defaults.tertiary,
    description: '#2a2a2a',
    hr: 'rgba(0,0,0,0.05)',
    link: 'blue',
    muted: 'rgba(0,0,0,0.45)',
    background: defaults.background,
    danger: defaults.danger,
  },
  primary: {
    bgColor: defaults.primary,
    color: defaults.background,
  },
  secondary: {
    bgColor: defaults.secondary,
    color: defaults.background,
  },
  tertiary: {
    bgColor: defaults.tertiary,
    color: defaults.background,
  },
  blackWhite: {
    bgColor: '#2a2a2a',
    color: defaults.background,
  },
  whiteBlack: {
    color: '#2a2a2a',
    bgColor: defaults.background,
  },
  borderRadius: '8px',
  boxShadow: `0px 30px 26px -18px rgba(0,0,0,0.3)`,
  graphPaper: 'rgba(173,216,230,0.75)',
  spacing: {
    paragraphWidth: '85ch',
  },
  titleFont: `'Consolas', monospace`,
  bodyFont: `'QuicksandVariable'`,
  inputTextIndent: '6px',
}
