import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { site, allMarkdownRemark } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "navbar" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                navItems {
                  label
                  linkType
                  url
                }
              }
            }
          }
        }
      }
    `
  )
  return {
    ...site.siteMetadata,
    links: allMarkdownRemark.edges[0].node.frontmatter.navItems,
  }
}

export default useSiteMetadata
