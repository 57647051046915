import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import React, { FC } from 'react'

import type { Theme } from '../../types'
import styled from '@emotion/styled'

const NoStyledA = styled('a')<{ theme: Theme }>`
  color: inherit;
  display: block;
  /* outline: none; */
  text-decoration: none;
  :hover {
    color: inherit;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 0 5px ${({ theme }) => theme.color.background},
      0 0 0 9px ${({ theme }) => theme.color.tertiary};
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`
const NoStyleNavLink = styled(GatsbyLink)<{ theme: Theme }>`
  color: inherit;
  text-decoration: none;
  /* outline: none; */
  /* :hover {
    text-decoration: none;
  } */
  :focus {
    outline: none;
    box-shadow: 0 0 0 5px ${({ theme }) => theme.color.background},
      0 0 0 9px ${({ theme }) => theme.color.tertiary};
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`
export interface LinkProps
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref' | 'to'> {
  active?: boolean
  href?: string
  linksOn?: boolean
  to?: string
}

const Link: FC<LinkProps> = ({
  children,
  to,
  href,
  linksOn = true,
  activeStyle,
  ...safeProps
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = to && /^\/(?!\/)/.test(to)
  console.log(to, internal)
  // Use gatsby-link for internal links, and <a> for others

  if (!internal || !linksOn) {
    return (
      <NoStyledA
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...safeProps}
      >
        {children}
      </NoStyledA>
    )
  }
  return (
    <NoStyleNavLink to={to} activeStyle={activeStyle} {...safeProps}>
      {children}
    </NoStyleNavLink>
  )
}

export default React.memo(Link)

export const GridLink = styled(Link)`
  display: grid;
`
